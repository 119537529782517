.navigation {
  top: 0;
  position: fixed;
  height: 5rem;
  width: 100%;
  background-color: $bg-color;

  a,
  a:visited {
    color: #bbb;
    text-decoration: none;
  }

  .dropdown {
    width: 100%;
    height: 5rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $bg-color;
    color: $text-color;
    position: relative;

    &:hover .dropdown-menu {
      display: block;
    }

    .menu {
      color: #bbb;
      font-size: 1.4rem;
      text-align: center;
    }

    .dropdown-menu {
      width: 100%;
      display: none;
      position: absolute;
      top: 5rem;
      background-color: #111;
    }

    li {
      height: 4rem;
      width: 100%;
      text-align: center;
      font-size: 1.4rem;
    }
  }
}

// For Desktops!!
@media (min-width: 700px) {
  .navigation {
    .dropdown {
      .menu {
        display: none;
      }

      &:hover .dropdown-menu {
        display: flex;
      }

      .dropdown-menu {
        position: static;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          height: auto;
        }
      }
    }
  }
}
