header.hero {
  .info {
    animation: fadeIn 0.5s ease-in;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  svg {
    // transition: all 1s linear;
    #left-arm,
    #right-arm {
      transform-origin: 0% 0%;
      transform-box: fill-box;
      animation: coding 3s linear infinite;
    }
    #left-arm {
      animation-delay: 1.5s;
    }
  }
  @keyframes coding {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(-100deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

footer {
  .heart {
    animation: bobUpandDown 1s linear infinite;
  }

  @keyframes bobUpandDown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(0);
    }
  }
}
