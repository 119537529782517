.contact {
  width: 80%;
  margin: 0 auto;

  &__title {
    font-size: 2rem;
    text-align: center;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    input {
      display: block;
      margin-top: 1rem;
      font-size: 1.4rem;
      padding: 0.5rem;
      border: none;
      border-radius: 1rem;
    }

    .button-div {
      margin: 1rem;

      button {
        text-align: center;
        padding: 1rem 2rem;
        font-size: 1.4rem;
        border-radius: 1rem;
      }
    }
  }
}

@media (min-width: $desktop) {
  .contact {
    margin-top: 5rem;

    form {
      input {
        width: 50%;
        padding-top: 1rem;
      }

      button {
        align-self: flex-end;
        margin: 1rem;
        padding: 1rem 2rem;
      }
    }
  }
}
