html {
  min-height: 100vh;
  scroll-behavior: smooth;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  min-height: 100%;
  width: 100%;
  box-sizing: border-box;
  font-size: 62.5%;
}

#root {
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 5rem;
  background: #feac5e; // fallback for old browsers
  background: -webkit-linear-gradient(to left, #4bc0c8, #c779d0, #feac5e);
  background: linear-gradient(to bottom right, #4bc0c8, #c779d0, #feac5e);
}

ul {
  list-style: none;
}
