.portfolio {
  width: 80%;
  margin: 6rem auto 0 auto;
  color: $portfolio-color;

  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  p {
    font-size: 1.4rem;
  }

  &__item {
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    border-radius: 2rem;
    height: 20rem;
    overflow: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .title {
      text-align: center;
    }

    .links {
      display: grid;
      grid-template-columns: 1fr 1fr;

      p {
        text-align: center;
      }

      a,
      a:visited {
        color: rgb(206, 24, 206);
        &:nth-child(2) {
          // color: rgb(245, 38, 23);
          color: green;
        }
      }

      i {
        font-size: 2rem;
      }
    }
  }
}

@media (min-width: $desktop) {
  .portfolio {
    &__items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &__item {
      width: 300px;
      margin: 1rem;
    }
  }
}
