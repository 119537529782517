.hero {
  height: calc(100vh - 5rem);
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  grid-template-areas: "info" "image";
  justify-items: center;

  .info {
    grid-area: "info";
    flex: 2;
    padding-top: 1rem;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .important {
      font-weight: bold;
    }

    h1 {
      font-size: 1.8rem;
      .important {
        display: block;
      }
    }

    p {
      font-size: 1.2rem;
    }
  }

  .image {
    grid-area: "image";
    width: 100%;
    margin-top: -3rem;
    display: flex;
    justify-content: center;

    svg {
      width: 80%;
      height: auto;
    }
  }
}

@media (min-width: 700px) {
  .hero {
    width: 80%;
    margin: 0 auto;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "info image";

    .info {
      width: auto;

      // .important {
      //   display: inline;
      // }

      h1 {
        margin-bottom: 1rem;
        font-size: 3rem;
      }

      p {
        font-size: 1.5rem;
      }
    }

    .image {
      justify-content: flex-end;
    }
  }
}
